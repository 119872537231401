.ui.menu.mobile-header-menu {
    border-bottom: none;
    background-color: white;
}

.ui.menu.mobile-header-menu .mobile-header-logo {
    width: 120px;
    height: auto;
}

.ui.menu.mobile-header-menu .hamburger-react > div {
    height: 1px!important;
}

.ui.menu.mobile-header-menu .hamburger-react > div:first-child {
    top: 18px!important;
}

.ui.menu.mobile-header-menu .hamburger-react > div:last-child {
    top: 28px!important;
}

.ui.sidebar.mobile-header-menu-sidebar {
    width: 100%;
    margin-top: 68px!important;
    padding-top: 15vh;
    border: none;
    text-align: center;
}

.ui.left.visible.sidebar.mobile-header-menu-sidebar {
    box-shadow: none;
}

.ui.sidebar.menu .item.mobile-header-menu-item {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2.5rem;
    padding-top: 20px;
    padding-bottom: 0;
}

.ui.sidebar.menu .item.mobile-header-menu-item:hover {
    background: none;
}

.ui.sidebar.menu .item.active.mobile-header-menu-item {
    background: none;
}

.ui.sidebar.menu .item.mobile-header-menu-item span {
    display: inline-block;
}

.ui.sidebar.menu .item.active.mobile-header-menu-item span {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: black;
}

.ui.sidebar.menu .item.mobile-header-menu-item::before {
    display: none;
}

.ui.sidebar.menu .item.mobile-header-menu-item:first-child::before {
    display: none!important;
}
