.ui.project {
    padding-top: 5em;
    padding-bottom: 10em;
}

.ui.project .header {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.ui.project .project-name {
    font-size: 3rem;
    margin-bottom: 2em;
}

.ui.project .project-nav {
    font-size: 1.7rem;
    margin-top: 1em;
    margin-bottom: 0;
}

.ui.project .project-nav:hover {
    cursor: pointer;
}