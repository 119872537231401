.ui.footer {
    margin-top: 5em;
    margin-bottom: 5em;
}

.ui.footer .footer-h1 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
}

.ui.footer .footer-h2 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
    margin-bottom: 0;
}

.ui.footer .footer-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
    margin-top: 0;
}

.ui.footer .footer-details {
    font-family: sans-serif;
    font-style: normal;
    font-size: 1.2rem;
    margin-bottom: 0;
}

.ui.footer .footer-copy-rights {
    margin-top: 2em;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
}

.ui.footer .footer-copy-rights .powered-by {
    color: black;
}

.ui.footer .footer-copy-rights .powered-by:hover {
    color: black;
    font-weight: 500;
}