@font-face {
    font-family: 'Acumin Pro';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Acumin-RPro.woff') format('woff');
}


@font-face {
    font-family: 'Acumin Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Acumin-ItPro.woff') format('woff');
}


@font-face {
    font-family: 'Acumin Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Acumin-BdPro.woff') format('woff');
}


@font-face {
    font-family: 'Acumin Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Acumin-BdItPro.woff') format('woff');
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

h1, h2, h3, h4 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
}