.ui.contact {
    margin-top: 5em;
    margin-bottom: 5em;
}

.ui.contact .ui.header.contact-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
}

.ui.contact .contact-description {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.2rem;
}

.ui.contact .contact-details-hr {
    margin-top: 5em;
}

.ui.contact .contact-details-block {
    margin-top: 5em;
    margin-bottom: 5em;
}

.ui.contact .contact-details-block .contact-details {
    font-family: sans-serif;
    font-style: normal;
    font-size: 1.2rem;
    margin-bottom: 0;
}

.ui.contact .contact-form input, label, textarea {
    font-family: "Poppins", sans-serif !important;
    font-weight: 300 !important;
    font-style: normal !important;
    font-size: 1.2rem !important;
}

.ui.contact .contact-form .contact-form-button {
    margin-top: 1em;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
    background-color: black;
    color: white;
    border-radius: 0;
    padding: 1.2em 2.2em;
}

.ui.contact .contact-form .contact-form-button:hover {
    background-color: darkslategray;
    color: white;
}