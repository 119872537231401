.ui.home-header {
    margin-top: 5em;
    margin-bottom: 3em;
}

.ui.home-header .ui.header {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.ui.home-header .ui.header.home-header-h1 {
    font-size: 3rem;
}

.ui.home-header .ui.header.home-header-h2 {
    font-size: 1.5rem;
}

.ui.centered.image.home-cover.desktop {
    width: 100%;
    height: 800px;
    max-height: 70vh;
    object-fit: cover;
}

.ui.home-work {
    margin-top: 5em;
    margin-bottom: 3em;
}

.ui.home-work .ui.header.home-work-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
}

.ui.home-work .home-work-description {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.2rem;
}

.ui.container.home-projects.desktop {
    padding-left: 5vh;
    padding-right: 5vh;
}

.ui.grid>.column.home-project-item:hover {
    cursor: pointer;
}

.ui.grid>.column.home-project-item:hover>.home-project-image {
    opacity: 0.5;
}

.ui.grid>.column.home-project-item .home-project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.ui.header.home-project-h4 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;
}

.ui.home-testimonials {
    margin-top: 8em;
    margin-bottom: 5em;
}

.ui.container.home-testimonials.desktop {
    padding-left: 5vh;
    padding-right: 5vh;
}

.ui.home-testimonials .ui.header.home-testimonials-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
}

.ui.home-testimonials .home-testimonials-item-content {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.3rem;
    margin-top: 3em;
}

.ui.home-testimonials .home-testimonials-item-author {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 1.1rem;
}

.ui.home-contact {
    margin-top: 5em;
    margin-bottom: 3em;
}

.ui.home-contact .ui.header.home-contact-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
    color: white;
}

.ui.home-contact .home-contact-description {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.2rem;
    padding-top: 1em;
    padding-bottom: 1em;
}

.ui.home-contact .home-contact-button {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
    background-color: white;
    color: black;
    border-radius: 0;
    padding: 1.2em 2.2em;
}

.ui.home-contact .home-contact-button:hover {
    background-color: gray;
    color: black;
}