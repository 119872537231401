.ui.about {
    margin-top: 5em;
    margin-bottom: 5em;
}

.ui.about .ui.header.about-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
}

.ui.about .about-description {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.2rem;
}

.ui.basic.about-contact {
    background-image: url('static/vis-about-cover.png');
    background-size: cover;
    padding-top: 10em;
    padding-bottom: 5em;
}

.ui.basic.about-contact .ui.header.about-contact-h3 {
    font-family: 'Acumin Pro', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
    color: white;
}

.ui.basic.about-contact .about-contact-button {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
    background-color: white;
    color: black;
    border-radius: 0;
    padding: 1.2em 2.2em;
}

.ui.basic.about-contact .about-contact-button:hover {
    background-color: gray;
    color: black;
}