.ui.secondary.pointing.menu.header-menu {
    border-bottom: none;
    background-color: white;
    padding: 20px;
}

.ui.secondary.pointing.menu .item.header-menu-logo {
    padding-left: 0;
}

.ui.secondary.pointing.menu .item.header-menu-item {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1.5em;
    padding-bottom: 0.25em;
    padding-left: 0;
    padding-right: 0;
    border-bottom-width: 1px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}